







































































































































import api from "@/api"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { DataDictionaryDtoPagedResultDto, UserTaskDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "UserTaskList",
  components: {
    PagedTableView,
    AbSelect,
    ExportButton,
    AgileAuditTag,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.id == type) {
          result = item.displayName;
          return;
        }
      });
      return result;
    },
    formatUser(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class UserTaskList extends Vue {
  importantLevel: any = []; // 紧急程度
  queryForm = {};
  userList: any[] = [];
  statusList: any[] = [];
  detailId = 0;

  created() {
    this.fetchDataDictionary();
    this.fetchUserList();
    this.fetchEnumType();
  }

  async fetchUserList() {
    await api.fundUser.getFundUserList({}).then((res: any) => {
      this.userList = res ?? [];
    });
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "TaskStatus",
      })
      .then((res) => {
        this.statusList = res;
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ImportantLevel",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.importantLevel = res.items;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.userTask.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "taskManagerCreate",
    });
  }

  // 查看详情
  jumpDetail(row: UserTaskDto) {
    // this.detailId = row.id!
    this.$router.push({
      name: "taskManagerDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 编辑
  handleEdit(row: UserTaskDto) {
    this.$router.push({
      name: "taskManagerEdit",
      params: { id: row.id!.toString() },
    });
  }
}
